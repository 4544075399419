import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let Tabs = class Tabs extends Vue {
    tabsChange(activeTab) {
        if (this.onTabChange)
            this.onTabChange(activeTab);
    }
};
__decorate([
    Prop()
], Tabs.prototype, "data", void 0);
__decorate([
    Prop()
], Tabs.prototype, "onTabChange", void 0);
__decorate([
    Prop()
], Tabs.prototype, "activeTab", void 0);
Tabs = __decorate([
    Component({})
], Tabs);
export default Tabs;
