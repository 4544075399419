import { __decorate } from "tslib";
import LegalInformation from '@/views/PersonalArea/userSetting/LegalInformation.vue';
import { Component, Watch } from 'vue-property-decorator';
import { IPersonalArea } from '@/interfaces/personal-area.interface';
import UserSettings from '@/views/PersonalArea/userSetting/UserSettings.vue';
import Tabs from '@/components/Tabs.vue';
import UserCard from '@/views/PersonalArea/userCard/UserCard.vue';
import MedicalCart from '@/views/PersonalArea/medicalCard/MedicalCard.vue';
import { IMenu } from '@/interfaces/layout.interface';
var PERSONAL_AREA_MENU = IMenu.PERSONAL_AREA_MENU;
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import PersonalAreaMixin from '@/mixins/personal-area-mixins';
import AccessibleCity from './AccessibleCity.vue';
import { openChatra, stringifyUserInfo } from '@/services/chatra.js';
let PersonalArea = class PersonalArea extends PersonalAreaMixin {
    constructor() {
        super(...arguments);
        this.tabsData = [
            {
                name: IPersonalArea.TabsName.USER_SETTINGS,
                label: 'Настройки пользователя',
                componentName: UserSettings,
            },
            {
                name: IPersonalArea.TabsName.MEDICAL_CARD,
                label: 'Медицинская карта',
                componentName: MedicalCart,
            },
        ];
        this.isLoading = false;
        this.activeTab = IPersonalArea.TabsName.USER_SETTINGS;
        this.mobileMenuData = PERSONAL_AREA_MENU;
    }
    async mounted() {
        this.isLoading = true;
        await this.$store.dispatch('personalArea/init');
        this.isLoading = false;
        this.onQueryChange();
    }
    get activeTabFromRoute() {
        return this.$route.query.activeTab;
    }
    onTabChange(data) {
        this.activeTab = data;
    }
    goToHomePage() {
        this.$router.push({ name: ROUTE_NAME.INDEX_PAGE });
    }
    logOut() {
        this.$store.dispatch('auth/logOut');
    }
    onQueryChange() {
        if (this.activeTabFromRoute) {
            this.onTabChange(this.activeTabFromRoute);
        }
    }
    handleOpenChatra() {
        openChatra(stringifyUserInfo());
    }
};
__decorate([
    Watch('activeTabFromRoute')
], PersonalArea.prototype, "onQueryChange", null);
PersonalArea = __decorate([
    Component({
        components: {
            UserSettings,
            Tabs,
            UserCard,
            MedicalCart,
            LegalInformation,
            AccessibleCity
        },
    })
], PersonalArea);
export default PersonalArea;
